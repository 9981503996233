import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatAccordion } from '@angular/material/expansion';
import { PageEvent } from '@angular/material/paginator';
import { MatListOption } from '@angular/material/list';
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { Hospital } from './hospital';
import { Facility } from './facility';
import { Reference } from './reference';
import { State } from './state';
import { HospitalService } from './hospital.service';
import { Project } from './project';
import { UpperCasePipe } from '@angular/common';
import { round, upperCase } from 'lodash';
import { MatExpansionModule } from "@angular/material/expansion";

interface IFilter {
  state: string;
  category: string;
  facilityLead: string;
  operationTheatre: boolean;
  labourRoom: boolean;
  outpatientDepartment: boolean;
  ambulatoryCareCenter: boolean;
  kriba: boolean;
  ambulanceCareComplex: boolean;
  equipment: string;
  clinicalService: string;
  bed: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild(MatExpansionModule) expansion: MatExpansionModule;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild("mapRef", { static: true }) mapElement: ElementRef;

  states: State[];
  categories: Reference[];
  clinicalServices: Reference[];
  equipments: Reference[];
  beds: Reference[];

  datasource: Hospital[];
  hospitals: Hospital[];
  facility: Facility;
  projects: Project[];

  total: number = 0;
  prev: number = 0;
  next: number = 5;

  imagePath: SafeResourceUrl;
  toggleFilter: boolean = false;
  toggleResult: boolean = true;
  toggleDetail: boolean = false;

  filterValue: string = "";

  isLoading = false;

  breakpoint: number;

  map: any;
  markers: any = [];
  infoContent: string;
  markerCluster: any;

  filter: IFilter = {
    state: '',
    category: '',
    facilityLead: '',
    operationTheatre: false,
    labourRoom: false,
    outpatientDepartment: false,
    ambulatoryCareCenter: false,
    kriba: false,
    ambulanceCareComplex: false,
    equipment: '',
    clinicalService: '',
    bed: '',
  };

  manual: string = "N";
  hiskkm: string = "N";
  sppd: string = "N";
  spp: string = "N";
  proprietary: string = "N";
  bbis: string = "N";

  svg: string =
    "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z";

  markerPin: any;
  bluePin: any;
  redPin: any;
  purplePin: any;
  greyPin: any;
  greenPin: any;
  yellowPin: any;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  latitude: number;
  longitude: number;
  zoom: number;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private hospitalService: HospitalService,
    private sanitizer: DomSanitizer
  ) {
    this.breakpoint = window.innerWidth < 768 ? 1 : 2;
  }

  ngOnInit(): void {
    this.hospitalService.getStates().subscribe((data: State[]) => {
      this.states = data;
    });

    this.hospitalService.getCategories().subscribe((data: Reference[]) => {
      this.categories = data;
    });

    this.populateList();
  }

  onResize(event: any) {
    this.breakpoint = event.target.innerWidth < 768 ? 1 : 2;
  }

  loadMap = () => {
    this.markerPin = {
      path: this.svg,
      fillColor: "black",
      fillOpacity: 0.8,
      strokeWeight: 0,
      rotation: 0,
      scale: 0.6,
      anchor: new window["google"].maps.Point(11, 10),
      labelOrigin: new window["google"].maps.Point(11, 10),
      size: new window["google"].maps.Size(20, 20),
      origin: new window["google"].maps.Point(0, 0),
    };

    this.purplePin = {
      path: this.svg,
      fillColor: "#6600CC",
      fillOpacity: 0.8,
      strokeWeight: 0,
      rotation: 0,
      scale: 0.6,
      anchor: new window["google"].maps.Point(11, 10),
      labelOrigin: new window["google"].maps.Point(11, 10),
      size: new window["google"].maps.Size(10, 10),
      origin: new window["google"].maps.Point(0, 0),
    };

    this.redPin = {
      path: this.svg,
      fillColor: "#CC3300",
      fillOpacity: 0.8,
      strokeWeight: 0,
      rotation: 0,
      scale: 0.6,
      anchor: new window["google"].maps.Point(11, 10),
      labelOrigin: new window["google"].maps.Point(11, 10),
      size: new window["google"].maps.Size(20, 20),
      origin: new window["google"].maps.Point(0, 0),
    };

    this.greenPin = {
      path: this.svg,
      fillColor: "#339900",
      fillOpacity: 0.8,
      strokeWeight: 0,
      rotation: 0,
      scale: 0.6,
      anchor: new window["google"].maps.Point(11, 10),
      labelOrigin: new window["google"].maps.Point(11, 10),
      size: new window["google"].maps.Size(20, 20),
      origin: new window["google"].maps.Point(0, 0),
    };

    this.greyPin = {
      path: this.svg,
      fillColor: "#666666",
      fillOpacity: 0.8,
      strokeWeight: 0,
      rotation: 0,
      scale: 0.6,
      anchor: new window["google"].maps.Point(11, 10),
      labelOrigin: new window["google"].maps.Point(11, 10),
      size: new window["google"].maps.Size(20, 20),
      origin: new window["google"].maps.Point(0, 0),
    };

    this.yellowPin = {
      path: this.svg,
      fillColor: "#FFFF00",
      fillOpacity: 0.8,
      strokeWeight: 0,
      rotation: 0,
      scale: 0.6,
      anchor: new window["google"].maps.Point(11, 10),
      labelOrigin: new window["google"].maps.Point(11, 10),
      size: new window["google"].maps.Size(20, 20),
      origin: new window["google"].maps.Point(0, 0),
    };

    this.bluePin = {
      path: this.svg,
      fillColor: "#3333FF",
      fillOpacity: 0.8,
      strokeWeight: 0,
      rotation: 0,
      scale: 0.6,
      anchor: new window["google"].maps.Point(11, 10),
      labelOrigin: new window["google"].maps.Point(11, 10),
      size: new window["google"].maps.Size(20, 20),
      origin: new window["google"].maps.Point(0, 0),
    };

    this.map = new window["google"].maps.Map(this.mapElement.nativeElement, {
      mapTypeId: window["google"].maps.MapTypeId.ROADMAP,
      styles: [
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563", visibility: "off" }],
        },
        {
          featureType: "poi.attraction",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.government",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.medical",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.place_of_worship",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.school",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.sports_complex",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ visibility: "off" }],
        },
      ],
    });

    const legend = document.getElementById("legend") as HTMLElement;
    this.map.controls[window["google"].maps.ControlPosition.LEFT_BOTTOM].push(legend);
    this.populateMapMarker();
  };

  renderMap() {
    window["initMap"] = () => {
      this.loadMap();
    };
    if (!window.document.getElementById("google-map-script")) {
      let gmap = window.document.createElement("script");
      gmap.id = "google-map-script";
      gmap.type = "text/javascript";
      gmap.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyC43HOGhGq4xq6eDvuiKrNqOYnvEymmIC8&callback=initMap";
      window.document.body.appendChild(gmap);
    } else {
      this.loadMap();
    }
  }

  populateList() {
    this.hospitalService.search().subscribe((data: Hospital[]) => {
      this.datasource = data;
      this.hospitals = this.datasource;
      this.total = this.hospitals.length;

      this._changeDetectorRef.detectChanges();

      this.renderMap();
    });
  }

  filterHospitals() {
    this.hospitals = this.filterByValue(this.datasource, this.filterValue);
    this.total = this.hospitals.length;

    this.deleteMarkers();
    this.populateMapMarker();
  }

  filterByValue(items: Hospital[], filter: string) {
    return items.filter((o) =>
      Object.keys(o).some((k) => {
        if (k === "facilityName") {
          return o[k].toLowerCase().includes(filter.toLowerCase());
        }
        return null;
      })
    );
  }

  advanceSearch() {
    this.hospitalService.advanceSearch(this.filter).subscribe((data: Hospital[]) => {
      this.datasource = data;
      this.hospitals = this.datasource;
      this.total = this.hospitals.length;

      this.deleteMarkers();
      this.populateMapMarker();

      this._changeDetectorRef.detectChanges();
    });

    this.toggleFilter = false;
    this.toggleResult = true;
  }

  resetSearch() {
    this.filter = {
      state: '',
      category: '',
      facilityLead: '',
      operationTheatre: false,
      labourRoom: false,
      outpatientDepartment: false,
      ambulatoryCareCenter: false,
      kriba: false,
      ambulanceCareComplex: false,
      equipment: '',
      clinicalService: '',
      bed: '',
    };

    this.advanceSearch();
  }

  clearFilter() {
    this.filterValue = "";
    this.hospitals = this.datasource;
    this.total = this.hospitals.length;

    this.deleteMarkers();
    this.populateMapMarker();
  }

  getPaginatorData(event: PageEvent): PageEvent {
    this.prev = event.pageIndex * event.pageSize;
    this.next = this.prev + event.pageSize;
    return event;
  }

  populateMapMarker() {
    let bounds = new window["google"].maps.LatLngBounds();

    for (let i = 0; i < this.hospitals.length; i++) {
      if (this.hospitals[i].facilityCategory?.indexOf("STATE HOSPITAL") > -1) {
        this.markerPin = this.purplePin;
      } else if (this.hospitals[i].facilityCategory?.indexOf("MAJOR SPECIALIST") > -1) {
        this.markerPin = this.redPin;
      } else if (this.hospitals[i].facilityCategory?.indexOf("MINOR SPECIALIST") > -1) {
        this.markerPin = this.greenPin;
      } else if (this.hospitals[i].facilityCategory?.indexOf("NON SPECIALIST") > -1) {
        this.markerPin = this.greyPin;
      } else if (this.hospitals[i].facilityCategory?.indexOf("SPECIAL MEDICAL INSTITUTION") > -1) {
        this.markerPin = this.yellowPin;
      } else if (this.hospitals[i].facilityCategory?.indexOf("SPECIAL INSTITUTION") > -1) {
        this.markerPin = this.bluePin;
      }
      let marker: any;

      if (this.hospitals[i].leadFacility === "0") {
        marker = new window["google"].maps.Marker({
          position: new window["google"].maps.LatLng(
            Number(this.hospitals[i].latitude),
            Number(this.hospitals[i].longitude)
          ),
          label: {
            text: this.camelize(this.hospitals[i].facilityName),
            fontWeight: "bold",
            color: "black",
            fontSize: "15",
          },
          title: this.camelize(this.hospitals[i].facilityName),
          animation: window["google"].maps.Animation.DROP,
          icon: this.markerPin,
          map: this.map,
        });
      } else {
        marker = new window["google"].maps.Marker({
          position: new window["google"].maps.LatLng(
            Number(this.hospitals[i].latitude),
            Number(this.hospitals[i].longitude)
          ),
          label: {
            text: this.camelize(this.hospitals[i].facilityName),
            fontWeight: "bold",
            color: "black",
            fontSize: "15",
          },
          title: this.camelize(this.hospitals[i].facilityName),
          animation: window["google"].maps.Animation.DROP,
          icon: this.markerPin,
          map: this.map,
        });
      }

      bounds.extend(
        new window["google"].maps.LatLng(
          Number(this.hospitals[i].latitude),
          Number(this.hospitals[i].longitude)
        )
      );

      let directionsDisplay = new window["google"].maps.DirectionsRenderer({
        suppressMarkers: true,
        preserveViewport: true,
      });

      let directionsService = new window["google"].maps.DirectionsService();

      marker.addListener("click", () => {
        let infoWindowContent =
          //'<img src=data:base64' +  + '</img>' +
          '<a href="https://www.google.com/maps/search/?api=1&query=' +
          encodeURI(this.camelize(this.hospitals[i].facilityName)) +
          '" target="_blank" rel="noopener"><strong>' +
          this.camelize(this.hospitals[i].facilityName) +
          "</strong></a><br/>" +
          this.upperCase(this.hospitals[i].facilityCategory) +
          "<br/>" +
          this.camelize(this.hospitals[i].clusterFacility) +
          " Cluster <br/>" +
          (this.hospitals[i].bedFulfillment === null ? 0 : this.hospitals[i].bedFulfillment) +
          " Hospital Beds";

        let infoWindow = new window["google"].maps.InfoWindow({
          content: infoWindowContent,
        });

        infoWindow.open(this.map, marker);

        infoWindow.addListener("closeclick", () => {
          directionsDisplay.setMap(null);
          directionsDisplay.setDirections(null);
        });

        directionsDisplay.setMap(this.map);

        if (this.hospitals[i].facilityCode !== this.hospitals[i].leadFacilityCode) {
          let request = {
            origin: new window["google"].maps.LatLng(
              Number(this.hospitals[i].latitude),
              Number(this.hospitals[i].longitude)
            ),
            destination: new window["google"].maps.LatLng(
              Number(this.hospitals[i].leadFacilityLatitude),
              Number(this.hospitals[i].leadFacilityLongitude)
            ),
            travelMode: window["google"].maps.TravelMode.DRIVING,
          };

          directionsService.route(request, (result: any, status: any) => {
            if (status === "OK") {
              directionsDisplay.setDirections(result);

              let totalDist = 0;
              let totalTime = 0;
              let myroute = result.routes[0];
              for (let j = 0; j < myroute.legs.length; j++) {
                totalDist += myroute.legs[j].distance.value;
                totalTime += myroute.legs[j].duration.value;
              }
              totalDist = totalDist / 1000;
              infoWindow.setContent(
                infoWindow.getContent() +
                  "<br/><strong>" +
                  totalDist.toFixed(2) +
                  "</strong> KM   <strong>" +
                  (totalTime / 60).toFixed(2) +
                  "</strong> Mins"
              );
            }
          });
        }

        if (
          this.hospitals[i].facilityCode === this.hospitals[i].leadFacilityCode ||
          this.hospitals[i].leadFacility === "0"
        ) {
          let request = {
            origin: new window["google"].maps.LatLng(
              Number(this.hospitals[i].leadFacilityLatitude),
              Number(this.hospitals[i].leadFacilityLongitude)
            ),
            destination: new window["google"].maps.LatLng(
              Number(this.hospitals[i].latitude),
              Number(this.hospitals[i].longitude)
            ),
            travelMode: window["google"].maps.TravelMode.DRIVING,
          };

          //     for (i = 0; i < request[i]; i++) {
          directionsService.route(request, (result: any, status: any) => {
            if (status === "OK") {
              directionsDisplay.setDirections(result);
            } else {
              window.alert("Directions failed due to" + status);
            }
          });
          //  }
        }
      });

      this.markers.push(marker);

      this.map.fitBounds(bounds);
    }
    // @ts-ignore MarkerClusterer defined via script
    this.markerCluster = new MarkerClusterer(this.map, this.markers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });

    this._changeDetectorRef.detectChanges();
  }

  setMapOnAll() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
  }

  deleteMarkers() {
    this.setMapOnAll();
    this.markers = [];
    this.markerCluster.clearMarkers();
    this.markerCluster.setMap(null);

    this._changeDetectorRef.detectChanges();
  }

  camelize(str: string) {
    return str
      .split(" ")
      .map(function (word, index) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  }

  upperCase(str: string) {
    return str
      .split(" ")
      .map(function (word, index) {
        return word.charAt(0).toLocaleUpperCase() + word.slice(1).toUpperCase();
      })
      .join(" ");
  }

  onSelectionChange(option: MatListOption) {
    this.hospitalService.findById(option.value.profileMasterId).subscribe((data: Facility) => {
      this.toggleResult = false;
      this.toggleDetail = true;

      if (data.systemList !== null) {
        var sysList = data.systemList.split(",");

        for (var j = 0; j < sysList.length; j++) {
          var item = sysList[j];
          if (item === "Manual") {
            this.manual = "Y";
          }
          if (item === "HISKKM") {
            this.hiskkm = "Y";
          }
          if (item === "SPPD") {
            this.sppd = "Y";
          }
          if (item === "SPP") {
            this.spp = "Y";
          }
          if (item === "Proprietary") {
            this.proprietary = "Y";
          }
          if (item === "BBIS") {
            this.bbis = "Y";
          }
        }
      }

      this.facility = data;

      if (data.mapImage !== null) {
        this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(
          "data:image/jpg;base64," + data.mapImage
        );
      } else {
        this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(
          "data:image/jpg;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
        );
      }

      this._changeDetectorRef.detectChanges();
    });
  }

  serviceType(value: string) {
    if (value === "IH") {
      return "In House";
    } else if (value === "Ci") {
      return "Cluster Integrated";
    } else if (value === "Cc") {
      return "Cluster Coordinated";
    } else if (value === "V") {
      return "Visiting Services";
    } else if (value === "Cn") {
      return "Inter-Cluster";
    } else if (value === "NIL") {
      return "Nil";
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
