<div fxFlex>
  <app-header [sidenav]="sidenav"></app-header>
  <mat-sidenav-container fxFill>
    <mat-sidenav #sidenav mode="over" ngClass.gt-sm="has-border">
      <nav>
        <mat-list>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/home" routerLinkActive="active" (click)="sidenav.close()">
            <span translate>Malaysia Hospital Directory</span>
          </a>
          <!-- <mat-divider></mat-divider>
          <a mat-list-item routerLink="/about" routerLinkActive="active" (click)="sidenav.close()">
            <span translate>About</span>
          </a> -->
          <mat-divider></mat-divider>
        </mat-list>
      </nav>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
