<mat-toolbar class="mat-elevation-z6 navbar" color="primary">
  <div>
    <img src="assets/img/logo.png" alt="Kementerian Kesihatan Malaysia" width="60" height="50" />
  </div>
  <div>
    <a class="brand" href="https://www.hospital.moh.gov.my" translate>APP_NAME</a>
  </div>
  <span fxFlex></span>
  <!-- <app-language-selector icon="true"></app-language-selector> -->
</mat-toolbar>
