<div #mapRef style="height: 100%; width: 100%"></div>
<div class="floating-panel">
  <mat-expansion-panel [expanded]="'true'" class="headers-align" *ngIf="toggleResult">
    <mat-expansion-panel-header>
      <mat-panel-description>
        <span translate [textContent]="total + ' record founds'"></span>
        <span fxFlex></span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Filter by [Name] or [State] or [Cluster]</mat-label>
      <input matInput type="text" (input)="filterHospitals()" [(ngModel)]="filterValue" />
      <button mat-button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
      <button
        mat-icon-button
        matSuffix
        (click)="toggleResult = false; toggleFilter = true"
        class="filter"
        aria-label="Advance filter"
      >
        <mat-icon>filter_list</mat-icon>
      </button>
    </mat-form-field>
    <mat-selection-list #hospital [multiple]="false" dense (selectionChange)="onSelectionChange($event.option)">
      <mat-list-option *ngFor="let hospital of hospitals | slice: prev:next; let lastItem = last" [value]="hospital">
        <mat-list-item>
          <p mat-line></p>
          <h3 mat-line>{{ hospital.facilityName }}</h3>
          <p mat-line>
            <span class="subline"
              >{{ hospital.stateDesc }} -- {{ hospital.clusterFacility }} -- {{ hospital.facilityCategory }}</span
            >
          </p>
        </mat-list-item>
        <mat-divider *ngIf="!lastItem"></mat-divider>
      </mat-list-option>
    </mat-selection-list>
    <mat-action-row>
      <mat-paginator
        [length]="total"
        [pageSize]="5"
        [pageSizeOptions]="[5, 10, 15]"
        [showFirstLastButtons]="'true'"
        (page)="getPaginatorData($event)"
      >
      </mat-paginator>
    </mat-action-row>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="'false'" class="headers-align" *ngIf="toggleResult">
    <mat-expansion-panel-header>
      <mat-panel-description>
        <mat-label><strong>Legend</strong> </mat-label>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-list>
      <mat-list-item
        ><mat-icon class="purple-icon"> location_on</mat-icon
        ><span style="font-size: 12px"> STATE HOSPITAL </span></mat-list-item
      >
      <mat-list-item
        ><mat-icon class="red-icon"> location_on</mat-icon
        ><span style="font-size: 12px"> MAJOR SPECIALIST </span></mat-list-item
      >
      <mat-list-item
        ><mat-icon class="green-icon"> location_on</mat-icon
        ><span style="font-size: 12px"> MINOR SPECIALIST </span></mat-list-item
      >
      <mat-list-item
        ><mat-icon class="grey-icon"> location_on</mat-icon><span style="font-size: 12px"> NON SPECIALIST </span>
      </mat-list-item>
      <mat-list-item
        ><mat-icon class="yellow-icon"> location_on</mat-icon
        ><span style="font-size: 12px"> SPECIAL MEDICAL INSTITUTION </span>
      </mat-list-item>
      <mat-list-item
        ><mat-icon class="blue-icon"> location_on</mat-icon><span style="font-size: 12px"> SPECIAL INSTITUTION </span>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="'true'" class="headers-align" *ngIf="toggleFilter">
    <mat-expansion-panel-header>
      <mat-panel-description>
        <span translate>Filter</span>
        <span fxFlex></span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div><h3>General Information</h3></div>
    <mat-grid-list [cols]="1" rowHeight="3.5rem">
      <mat-grid-tile>
        <div fxFlex fxLayoutAlign="start center">
          <mat-form-field style="width: 100%">
            <mat-label>State</mat-label>
            <mat-select [(ngModel)]="filter.state">
              <mat-option *ngFor="let state of states" [value]="state.stateCode">
                {{ state.fullDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div fxFlex fxLayoutAlign="start center">
          <mat-form-field style="width: 100%">
            <mat-label>Category</mat-label>
            <mat-select [(ngModel)]="filter.category">
              <mat-option *ngFor="let category of categories" [value]="category.referenceCode">
                {{ category.fullDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div fxFlex fxLayoutAlign="start center">
          <mat-radio-group aria-label="Select an option" [(ngModel)]="filter.facilityLead">
            <mat-radio-button value="0">Lead Hospital</mat-radio-button>
            <mat-radio-button value="1">Non Lead Hospital</mat-radio-button>
            <mat-radio-button value="2">Others</mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-divider></mat-divider>
    <div><h3>Hospital Information</h3></div>
    <mat-grid-list [cols]="1" rowHeight="3.5rem">
      <mat-grid-tile>
        <div fxFlex fxLayoutAlign="start center">
          <mat-slide-toggle [(ngModel)]="filter.operationTheatre"> Operation Theatre </mat-slide-toggle>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div fxFlex fxLayoutAlign="start center">
          <mat-slide-toggle [(ngModel)]="filter.labourRoom"> Labour Room </mat-slide-toggle>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div fxFlex fxLayoutAlign="start center">
          <mat-slide-toggle [(ngModel)]="filter.outpatientDepartment"> Outpatient Department </mat-slide-toggle>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div fxFlex fxLayoutAlign="start center">
          <mat-form-field style="width: 100%">
            <mat-label>Critical Care Beds</mat-label>
            <mat-select [(ngModel)]="filter.bed">
              <mat-option *ngFor="let bed of beds" [value]="bed.referenceCode">
                {{ bed.fullDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-divider></mat-divider>
    <div><h3>Facilities & Equipments</h3></div>
    <mat-grid-list [cols]="1" rowHeight="3.5rem">
      <mat-grid-tile>
        <div fxFlex fxLayoutAlign="start center">
          <mat-slide-toggle [(ngModel)]="filter.ambulatoryCareCenter"> Ambulatory Care Center (ACC) </mat-slide-toggle>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div fxFlex fxLayoutAlign="start center">
          <mat-slide-toggle [(ngModel)]="filter.kriba"> Kompleks Rawatan Ibu dan Bayi (KRIBA) </mat-slide-toggle>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div fxFlex fxLayoutAlign="start center">
          <mat-slide-toggle [(ngModel)]="filter.ambulanceCareComplex"> Ambulance Care Complex </mat-slide-toggle>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div fxFlex fxLayoutAlign="start center">
          <mat-form-field style="width: 100%">
            <mat-label>Equipment</mat-label>
            <mat-select [(ngModel)]="filter.equipment">
              <mat-option *ngFor="let equipment of equipments" [value]="equipment.referenceCode">
                {{ equipment.fullDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-divider></mat-divider>
    <div><h3>Clinical Services</h3></div>
    <mat-grid-list [cols]="1" rowHeight="3.5rem">
      <mat-grid-tile>
        <div fxFlex fxLayoutAlign="start center">
          <mat-form-field style="width: 100%">
            <mat-label>Services</mat-label>
            <mat-select [(ngModel)]="filter.clinicalService">
              <mat-option *ngFor="let service of clinicalServices" [value]="service.referenceCode">
                {{ service.fullDesc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-action-row>
      <button mat-button aria-label="Cancel" (click)="toggleResult = true; toggleFilter = false">
        <span translate>Cancel</span>
      </button>
      <button mat-button aria-label="Reset" (click)="resetSearch()">
        <mat-icon></mat-icon>
        <span translate>Clear All Selection</span>
      </button>
      <button mat-button aria-label="Search" (click)="advanceSearch()">
        <mat-icon>search</mat-icon>
        <span translate>Search</span>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</div>

<div class="floating-panel-detail">
  <mat-expansion-panel [expanded]="'true'" class="headers-align" *ngIf="toggleDetail">
    <mat-expansion-panel-header>
      <mat-panel-description>
        <span translate>{{ facility.facilityName }}</span>
        <span fxFlex></span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-tab-group dynamicHeight>
      <mat-tab label="Hospital Information">
        <img alt="facade" [src]="imagePath" class="image" />
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Facility Name: &nbsp;<strong>{{ facility.facilityName }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Facility Category: &nbsp;<strong>{{ facility.facilityCategory }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Facility Type: &nbsp;<strong>{{ facility.facilityType }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Lead Facility: &nbsp;<strong>{{
                facility.leadFacilityFulldesc === null ? 'NOT SPECIFIED' : facility.leadFacilityFulldesc
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Specialties: &nbsp;<strong>{{
                facility.totalSpecialties === null ? '0' : facility.totalSpecialties
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Last Updated: &nbsp;<strong>{{ facility.reportDate | date: 'yyyy' }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>
      <mat-tab label="Hospital Beds">
        <div fxFlex fxLayoutAlign="start center"><h3>Hospital Beds</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Hospital Beds (Official): &nbsp;<strong>{{
                facility.bedFulfillmentOfficial === null ? '0' : (facility.bedFulfillmentOfficial | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Hospital Beds (Operational): &nbsp;<strong>{{
                facility.bedFulfillment === null ? '0' : (facility.bedFulfillment | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Bed Occupancy Rate (BOR) (Operational): &nbsp;<strong>{{
                facility.useRate === null ? '0' : facility.useRate
              }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <div fxFlex fxLayoutAlign="start center"><h3>Critical Care Beds</h3></div>
        <div style="overflow-x: auto">
          <table>
            <thead>
              <tr>
                <th style="width: 40%"></th>
                <th style="text-align: right; width: 20%">Beds</th>
                <th style="text-align: right; width: 20%">BOR</th>
                <th style="text-align: right; width: 20%">Non-Operating</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>General Intensive Care Unit (GICU)</td>
                <td style="text-align: right">
                  {{ facility.icuBed === null ? '0' : (facility.icuBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.icuBor === null ? '0' : facility.icuBor }}</td>
                <td style="text-align: right">
                  {{ facility.icuNoopr === null ? '0' : (facility.icuNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Pediatric Intensive Care Unit (PICU)</td>
                <td style="text-align: right">
                  {{ facility.picuBed === null ? '0' : (facility.picuBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.picuBor === null ? '0' : facility.picuBor }}</td>
                <td style="text-align: right">
                  {{ facility.picuNoopr === null ? '0' : (facility.picuNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Neonatal Intensive Care Unit (NICU)</td>
                <td style="text-align: right">
                  {{ facility.nicuBed === null ? '0' : (facility.nicuBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.nicuBor === null ? '0' : facility.nicuBor }}</td>
                <td style="text-align: right">
                  {{ facility.nicuNoopr === null ? '0' : (facility.nicuNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>High Dependency Ward (HDW)</td>
                <td style="text-align: right">
                  {{ facility.hdwBed === null ? '0' : (facility.hdwBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.hdwBor === null ? '0' : facility.hdwBor }}</td>
                <td style="text-align: right">
                  {{ facility.hdwNoopr === null ? '0' : (facility.hdwNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Coronary Care Unit (CCU)</td>
                <td style="text-align: right">
                  {{ facility.ccuBed === null ? '0' : (facility.ccuBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.ccuBor === null ? '0' : facility.ccuBor }}</td>
                <td style="text-align: right">
                  {{ facility.ccuNoopr === null ? '0' : (facility.ccuNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Cardiothoracic Intensive Care Unit (CICU)</td>
                <td style="text-align: right">
                  {{ facility.cicuBed === null ? '0' : (facility.cicuBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.cicuBor === null ? '0' : facility.cicuBor }}</td>
                <td style="text-align: right">
                  {{ facility.cicuNoopr === null ? '0' : (facility.cicuNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Neurosurgery Intensive Care Unit (Neuro ICU)</td>
                <td style="text-align: right">
                  {{ facility.neicuBed === null ? '0' : (facility.neicuBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.neicuBor === null ? '0' : facility.neicuBor }}</td>
                <td style="text-align: right">
                  {{ facility.neicuNoopr === null ? '0' : (facility.neicuNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Paediatric Cardiothoracic Intensive Care Unit (PCICU)</td>
                <td style="text-align: right">
                  {{ facility.pcicuBed === null ? '0' : (facility.pcicuBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.pcicuBor === null ? '0' : facility.pcicuBor }}</td>
                <td style="text-align: right">
                  {{ facility.pcicuNoopr === null ? '0' : (facility.pcicuNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Palliative Care Unit (PCU)</td>
                <td style="text-align: right">
                  {{ facility.pcuBed === null ? '0' : (facility.pcuBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.pcuBor === null ? '0' : facility.pcuBor }}</td>
                <td style="text-align: right">
                  {{ facility.picuNoopr === null ? '0' : (facility.picuNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Special Care Nursery (SCN)</td>
                <td style="text-align: right">
                  {{ facility.scnBed === null ? '0' : (facility.scnBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.scnBor === null ? '0' : facility.scnBor }}</td>
                <td style="text-align: right">
                  {{ facility.scnNoopr === null ? '0' : (facility.scnNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Intensive Care Unit (ICU PRIBA) Obstetrik</td>
                <td style="text-align: right">
                  {{ facility.icuPribaBed === null ? '0' : (facility.icuPribaBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.icuPribaBor === null ? '0' : facility.icuPribaBor }}</td>
                <td style="text-align: right">
                  {{ facility.icuPribaNoopr === null ? '0' : (facility.icuPribaNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Pediatric High Dependancy Unit (PHDU)</td>
                <td style="text-align: right">
                  {{ facility.phduBed === null ? '0' : (facility.phduBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.phduBor === null ? '0' : facility.phduBor }}</td>
                <td style="text-align: right">
                  {{ facility.phduNoopr === null ? '0' : (facility.phduNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Post Anaes Critical Unit (PACU)</td>
                <td style="text-align: right">
                  {{ facility.pacuBed === null ? '0' : (facility.pacuBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.pacuBor === null ? '0' : facility.pacuBor }}</td>
                <td style="text-align: right">
                  {{ facility.pacuNoopr === null ? '0' : (facility.pacuNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Burn ICU (Burn ICU)</td>
                <td style="text-align: right">
                  {{ facility.burnBed === null ? '0' : (facility.burnBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.burnBor === null ? '0' : facility.burnBor }}</td>
                <td style="text-align: right">
                  {{ facility.burnNoopr === null ? '0' : (facility.burnNoopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Combination ICU (Combination ICU)</td>
                <td style="text-align: right">
                  {{ facility.icu2Bed === null ? '0' : (facility.icu2Bed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.icu2Bor === null ? '0' : facility.icu2Bor }}</td>
                <td style="text-align: right">
                  {{ facility.icu2Noopr === null ? '0' : (facility.icu2Noopr | number: '1.') }}
                </td>
              </tr>
              <tr>
                <td>Urology Intensive Care Unit(Urol ICU)</td>
                <td style="text-align: right">
                  {{ facility.uroIcuBed === null ? '0' : (facility.uroIcuBed | number: '1.') }}
                </td>
                <td style="text-align: right">{{ facility.uroIcuBor === null ? '0' : facility.uroIcuBor }}</td>
                <td style="text-align: right">
                  {{ facility.uroIcuNoopr === null ? '0' : (facility.uroIcuNoopr | number: '1.') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <mat-divider></mat-divider>
        <div fxFlex fxLayoutAlign="start center"><h3>Operation Theatre</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2.5rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total No. of Functional General (GA) Operation Theatres: &nbsp;<strong>{{
                facility.totalOt === null ? '0' : (facility.totalOt | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile
            style="
              left: calc((0% - 1px + 1px) * 1);
              width: calc((25% - 1px) * 1 + 0px);
              top: 0px;
              height: calc(2.5rem + 0px);
            "
          >
            <div fxFlex fxLayoutAlign="start right"></div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total No. of Local (LA) Operation Theatres: &nbsp;<strong>{{
                facility.totalLaOt === null ? '0' : (facility.totalLaOt | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total General Operation Theatres : &nbsp;<strong>{{
                facility.totalGeneralOt === null ? '0' : (facility.totalGeneralOt | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Maternal Operation Theatres : &nbsp;<strong>{{
                facility.totalMaternalOt === null ? '0' : (facility.totalMaternalOt | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Neuro Operation Theatres : &nbsp;<strong>{{
                facility.totalNeuroOt === null ? '0' : (facility.totalNeuroOt | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Uro/Robotic Operation Theatres : &nbsp;<strong>{{
                facility.totalUroRoboticOt === null ? '0' : (facility.totalUroRoboticOt | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Cardiac Operation Theatres : &nbsp;<strong>{{
                facility.totalCardiacOt === null ? '0' : (facility.totalCardiacOt | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total No. of Non-Functioning Operation Theatres: &nbsp;<strong>{{
                facility.totalOtNoopr === null ? '0' : (facility.totalOtNoopr | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total No. of Non-Functioning LA Operation Theatres: &nbsp;<strong>{{
                facility.totalLaOtNoopr === null ? '0' : (facility.totalLaOtNoopr | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <div fxFlex fxLayoutAlign="start center"><h3>Labour Room</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total No. of Labour Rooms: &nbsp;<strong>{{
                facility.totalLabourRoom === null ? '0' : (facility.totalLabourRoom | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total No. of Non-Operating Labour Rooms: &nbsp;<strong>{{
                facility.totalLabourRoomNoopr === null ? '0' : (facility.totalLabourRoomNoopr | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>

      <mat-tab label="Facilities & Equipments">
        <div fxFlex fxLayoutAlign="start center"><h3>Special Complex</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Ambulatory Care Conter (ACC): &nbsp;<strong>{{
                facility.ambulatoryCareCenter === 'N' ? 'No' : 'Yes'
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Kompleks Rawatan Ibu dan Bayi (KRIBA): &nbsp;<strong>{{
                facility.kribaCenter === 'N' ? 'No' : 'Yes'
              }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <div fxFlex fxLayoutAlign="start center"><h3>Ambulance</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Ambulance Care Complex: &nbsp;<strong>{{ facility.complexAcc === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Ambulance: &nbsp;<strong>{{
                facility.totalAmbulance === null ? '0' : (facility.totalAmbulance | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Functioning Ambulance: &nbsp;<strong>{{
                facility.totalFunctioningAmbulance === null ? '0' : (facility.totalFunctioningAmbulance | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Functioning Ambulance (BER): &nbsp;<strong>{{
                facility.totalBerAmbulance === null ? '0' : (facility.totalBerAmbulance | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Non-Functioning Ambulance (Repairable): &nbsp;<strong>{{
                facility.totalFunctioningBerAmbulance === null
                  ? '0'
                  : (facility.totalFunctioningBerAmbulance | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Non-Functioning Ambulance (BER): &nbsp;<strong>{{
                facility.totalBerAmbulance === null ? '0' : (facility.totalBerAmbulance | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <div fxFlex fxLayoutAlign="start center"><h3>Equipments</h3></div>

        <mat-accordion class="example-headers-align">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Radiology </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total MRI: &nbsp;<strong>{{
                    facility.totalMri === null ? '0' : (facility.totalMri | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total CT SCAN: &nbsp;<strong>{{
                    facility.totalCtscan === null ? '0' : (facility.totalCtscan | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Digital X ray: &nbsp;<strong>{{
                    facility.totalDigitalXray === null ? '0' : (facility.totalDigitalXray | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total General X ray: &nbsp;<strong>{{
                    facility.totalGeneralXray === null ? '0' : (facility.totalGeneralXray | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Mammogram (Digital and Non Digital): &nbsp;<strong>{{
                    facility.totalMammogram === null ? '0' : (facility.totalMammogram | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Angiogram Machine: &nbsp;<strong>{{
                    facility.totalAngiogram === null ? '0' : (facility.totalAngiogram | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Fluroscopy Machine: &nbsp;<strong>{{
                    facility.totalFluroscopy === null ? '0' : (facility.totalFluroscopy | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Ultrasound Machine: &nbsp;<strong>{{
                    facility.totalUltrasoundRadiology === null
                      ? '0'
                      : (facility.totalUltrasoundRadiology | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Medical / Cardiology / Nephrology </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Invasive Cardiac Lab: &nbsp;<strong>{{
                    facility.totalInvasiveCardiacLab === null ? '0' : (facility.totalInvasiveCardiacLab | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Echocardiogram Machine: &nbsp;<strong>{{
                    facility.totalEchocardiogram === null ? '0' : (facility.totalEchocardiogram | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Haemodialysis Machine: &nbsp;<strong>{{
                    facility.totalHaemodialysisMachine === null
                      ? '0'
                      : (facility.totalHaemodialysisMachine | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Ultrasound Machine: &nbsp;<strong>{{
                    facility.totalUltrasoundMedical === null ? '0' : (facility.totalUltrasoundMedical | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Oral Maxillo Facial </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total CBCT: &nbsp;<strong>{{ facility.totalCbct === null ? '0' : facility.totalCbct }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Ultrasound Machine: &nbsp;<strong>{{
                    facility.totalUltrasoundOral === null ? '0' : (facility.totalUltrasoundOral | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Urology </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total ESWL Machine: &nbsp;<strong>{{
                    facility.totalEswlMachine === null ? '0' : (facility.totalEswlMachine | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Ultrasound Machine: &nbsp;<strong>{{
                    facility.totalUltrasoundUrology === null ? '0' : (facility.totalUltrasoundUrology | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Anaesthsesia </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Invasive Ventilator: &nbsp;<strong>{{
                    facility.totalVentilator === null ? '0' : (facility.totalVentilator | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total GA Machine: &nbsp;<strong>{{
                    facility.totalGaMachine === null ? '0' : (facility.totalGaMachine | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Non-Invasive Machine: &nbsp;<strong>{{
                    facility.totalNonInvasiveVentilator === null
                      ? '0'
                      : (facility.totalNonInvasiveVentilator | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Ultrasound Machine: &nbsp;<strong>{{
                    facility.totalUltrasoundAnaeshesia === null
                      ? '0'
                      : (facility.totalUltrasoundAnaeshesia | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Nuclear Medicine </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total PET CT: &nbsp;<strong>{{
                    facility.totalPetCt === null ? '0' : (facility.totalPetCt | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Cyclotron: &nbsp;<strong>{{
                    facility.totalCyclotron === null ? '0' : (facility.totalCyclotron | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Spec CT: &nbsp;<strong>{{
                    facility.totalSpecCt === null ? '0' : (facility.totalSpecCt | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Ultrasound Machine: &nbsp;<strong>{{
                    facility.totalUltrasoundNuclear === null ? '0' : (facility.totalUltrasoundNuclear | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Oncology </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Linear Accelerator Machine: &nbsp;<strong>{{
                    facility.totalLinearAccelerator === null ? '0' : (facility.totalLinearAccelerator | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Brachytheraphy Machine: &nbsp;<strong>{{
                    facility.totalBrachytheraphy === null ? '0' : (facility.totalBrachytheraphy | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Ultrasound Machine: &nbsp;<strong>{{
                    facility.totalUltrasoundOncology === null ? '0' : (facility.totalUltrasoundOncology | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Neurosurgery </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Neuro Microscope: &nbsp;<strong>{{
                    facility.totalNeuroMicroscope === null ? '0' : (facility.totalNeuroMicroscope | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div fxFlex fxLayoutAlign="start center">
                  Total Ultrasound Machine: &nbsp;<strong>{{
                    facility.totalUltrasoundNeurosurgery === null
                      ? '0'
                      : (facility.totalUltrasoundNeurosurgery | number: '1.')
                  }}</strong>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-tab>
      <mat-tab label="Human Resource">
        <div fxFlex fxLayoutAlign="start center"><h3>Doctor</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of Specialists: &nbsp;<strong>{{
                facility.totalSpecialist === null ? '0' : (facility.totalSpecialist | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of Medical Officers (MO): &nbsp;<strong>{{
                facility.totalMedicalOfficer === null ? '0' : (facility.totalMedicalOfficer | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <!-- <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of Dental Specialists: &nbsp;<strong>{{
                facility.totalDentalSpecialist === null ? '0' : (facility.totalDentalSpecialist | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile> -->
        </mat-grid-list>
        <mat-divider></mat-divider>
        <div fxFlex fxLayoutAlign="start center"><h3>Paramedic</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of Nurses: &nbsp;<strong>{{
                facility.totalNurse === null ? '0' : (facility.totalNurse | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Medical Attendants: &nbsp;<strong>{{
                facility.totalMedicalAttendant === null ? '0' : (facility.totalMedicalAttendant | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of Assistant Medical Officers: &nbsp;<strong>{{
                facility.totalAssistantMedicalOfficer === null
                  ? '0'
                  : (facility.totalAssistantMedicalOfficer | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <div fxFlex fxLayoutAlign="start center"><h3>Allied Health</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of Allied Health Workforce: &nbsp;<strong>{{
                facility.totalAlliedHealthWorkforce === null
                  ? '0'
                  : (facility.totalAlliedHealthWorkforce | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <button mat-button aria-label="Human Resource List" (click)="toggleDetail = false; toggleResult = true">
          <span translate><h3>Human Resource List</h3></span>
        </button>
      </mat-tab>

      <mat-tab label="Workload">
        <div fxFlex fxLayoutAlign="start center"><h3>Inpatients</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Admission per Year: &nbsp;<strong>{{
                facility.totalEncounter === null ? '0' : (facility.totalEncounter | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Average Length of Stay (ALOS) (days): &nbsp;<strong>{{
                facility.averageTimeframe === null ? '0' : (facility.averageTimeframe | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Average Admission per Day: &nbsp;<strong>{{
                facility.dailyAverageEncounter === null ? '0' : (facility.dailyAverageEncounter | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <div fxFlex fxLayoutAlign="start center"><h3>Outpatients</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of ETD Patient per Year: &nbsp;<strong>{{
                facility.totalEmergencyPatient === null ? '0' : (facility.totalEmergencyPatient | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Outpatient Department: &nbsp;<strong>{{ facility.outpatientDepartment === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of Specialist Clinics Patient per Year: &nbsp;<strong>{{
                facility.totalSpecilistPatient === null ? '0' : (facility.totalSpecilistPatient | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of JPL Patients Per Year: &nbsp;<strong>{{
                facility.totalOutpatient === null ? '0' : (facility.totalOutpatient | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of Patients to PAC: &nbsp;<strong>{{
                facility.totalNoPatientToPac === null ? '0' : (facility.totalNoPatientToPac | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of Patients to Klinik Khas: &nbsp;<strong>{{
                facility.totalNoPatientToKlinikWarga === null
                  ? '0'
                  : (facility.totalNoPatientToKlinikWarga | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <div fxFlex fxLayoutAlign="start center"><h3>Intensive Care</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Admission to General Intensive Care per Year: &nbsp;<strong>{{
                facility.totalGicAdmission === null ? '0' : (facility.totalGicAdmission | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Admission to Paediatric Intensive Care per Year: &nbsp;<strong>{{
                facility.totalPicAdmission === null ? '0' : (facility.totalPicAdmission | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Admission to Neonatal Intensive Care per Year: &nbsp;<strong>{{
                facility.totalNeoAdmission === null ? '0' : (facility.totalNeoAdmission | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Admission to High Dependency Ward per Year: &nbsp;<strong>{{
                facility.totalHdwAdmission === null ? '0' : (facility.totalHdwAdmission | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Admission to Coronary Care per Year: &nbsp;<strong>{{
                facility.totalCcuAdmission === null ? '0' : (facility.totalCcuAdmission | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <div fxFlex fxLayoutAlign="start center"><h3>Others</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of Surgeries per Year: &nbsp;<strong>{{
                facility.totalSurgery === null ? '0' : (facility.totalSurgery | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of Day Care Procedure (Surgical) per Year: &nbsp;<strong>{{
                facility.totalSurgicalDcp === null ? '0' : (facility.totalSurgicalDcp | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of Deliveries per Year: &nbsp;<strong>{{
                facility.totalNewBorn === null ? '0' : (facility.totalNewBorn | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Total Number of Day Care Procedure (Medical) per Year: &nbsp;<strong>{{
                facility.totalMedicalDcp === null ? '0' : (facility.totalMedicalDcp | number: '1.')
              }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <div fxFlex fxLayoutAlign="start center"><h3>Inpatient Severity of Illness (SOI)</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              SOI I (%): &nbsp;<strong>{{
                facility.saverityOfIllness1 === null ? '0' : facility.saverityOfIllness1
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              SOI II (%): &nbsp;<strong>{{
                facility.saverityOfIllness2 === null ? '0' : facility.saverityOfIllness2
              }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              SOI III (%): &nbsp;<strong>{{
                facility.saverityOfIllness3 === null ? '0' : facility.saverityOfIllness3
              }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>

      <mat-tab label="Clinical Services">
        <mat-accordion class="example-headers-align">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Medical-Based </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: auto">
              <table>
                <thead>
                  <tr>
                    <th style="width: 50%">Specialty</th>
                    <th style="width: 30%">Type</th>
                    <th style="width: 20%; text-align: right">Total Number of In House Specialist</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Internal Medicine</td>
                    <td>{{ serviceType(facility.generalMedicine) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.generalMedicineSpecialist === null
                          ? '0'
                          : (facility.generalMedicineSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Cardiology</td>
                    <td>{{ serviceType(facility.cardiology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.cardiologySpecialist === null ? '0' : (facility.cardiologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Dermatology</td>
                    <td>{{ serviceType(facility.dermatology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.dermatologySpecialist === null ? '0' : (facility.dermatologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Endocrinology</td>
                    <td>{{ serviceType(facility.endocrinology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.endocrinologySpecialist === null
                          ? '0'
                          : (facility.endocrinologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Gastroenterology</td>
                    <td>{{ serviceType(facility.gastroenterology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.gastroenterologySpecialist === null
                          ? '0'
                          : (facility.gastroenterologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Genetics</td>
                    <td>{{ serviceType(facility.genetics) }}</td>
                    <td style="text-align: right">
                      {{ facility.geneticsSpecialist === null ? '0' : (facility.geneticsSpecialist | number: '1.') }}
                    </td>
                  </tr>
                  <tr>
                    <td>Geriatric</td>
                    <td>{{ serviceType(facility.geriatric) }}</td>
                    <td style="text-align: right">
                      {{ facility.geriatricSpecialist === null ? '0' : (facility.geriatricSpecialist | number: '1.') }}
                    </td>
                  </tr>
                  <tr>
                    <td>Haematology</td>
                    <td>{{ serviceType(facility.haematology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.haematologySpecialist === null ? '0' : (facility.haematologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Hepatology</td>
                    <td>{{ serviceType(facility.hepatology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.hepatologySpecialist === null ? '0' : (facility.hepatologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Infectious Disease</td>
                    <td>{{ serviceType(facility.infectiousDisease) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.infectiousDiseaseSpecialist === null
                          ? '0'
                          : (facility.infectiousDiseaseSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Nephrology</td>
                    <td>{{ serviceType(facility.nephrology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.nephrologySpecialist === null ? '0' : (facility.nephrologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Neurology</td>
                    <td>{{ serviceType(facility.neurology) }}</td>
                    <td style="text-align: right">
                      {{ facility.neurologySpecialist === null ? '0' : (facility.neurologySpecialist | number: '1.') }}
                    </td>
                  </tr>
                  <tr>
                    <td>Nuclear Medicine</td>
                    <td>{{ serviceType(facility.nuclearMedicine) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.nuclearMedicineSpecialist === null
                          ? '0'
                          : (facility.nuclearMedicineSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Oncology</td>
                    <td>{{ serviceType(facility.oncology) }}</td>
                    <td style="text-align: right">
                      {{ facility.oncologySpecialist === null ? '0' : (facility.oncologySpecialist | number: '1.') }}
                    </td>
                  </tr>
                  <tr>
                    <td>Palliative Care Medicine</td>
                    <td>{{ serviceType(facility.palliativeCareMedicine) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.palliativeCareMedicineSpecialist === null
                          ? '0'
                          : (facility.palliativeCareMedicineSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Rehabilitation</td>
                    <td>{{ serviceType(facility.rehabilitation) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.rehabilitationSpecialist === null
                          ? '0'
                          : (facility.rehabilitationSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Respiratory Medicine</td>
                    <td>{{ serviceType(facility.respiratoryMedicine) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.respiratoryMedicineSpecialist === null
                          ? '0'
                          : (facility.respiratoryMedicineSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Rheumatology</td>
                    <td>{{ serviceType(facility.rheumatology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.rheumatologySpecialist === null
                          ? '0'
                          : (facility.rheumatologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Sports Medicine</td>
                    <td>{{ serviceType(facility.sportsMedicine) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.sportsMedicineSpecialist === null
                          ? '0'
                          : (facility.sportsMedicineSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Emergency Medicine</td>
                    <td>{{ serviceType(facility.emergencyMedicine) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.emergencyMedicineSpecialist === null
                          ? '0'
                          : (facility.emergencyMedicineSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Public Health</td>
                    <td>{{ serviceType(facility.publicHealth) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.publicHealthSpecialist === null
                          ? '0'
                          : (facility.publicHealthSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Family Medicine</td>
                    <td>{{ serviceType(facility.familyMedicine) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.familyMedicineSpecialist === null
                          ? '0'
                          : (facility.familyMedicineSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Surgical-Based </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: auto">
              <table>
                <thead>
                  <tr>
                    <th style="width: 50%">Specialty</th>
                    <th style="width: 30%">Type</th>
                    <th style="width: 20%; text-align: right">Total Number of In House Specialist</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>General Surgery</td>
                    <td>{{ serviceType(facility.generalSurgery) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.generalSurgerySpecialist === null
                          ? '0'
                          : (facility.generalSurgerySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Breast & Endocrine Surgery</td>
                    <td>{{ serviceType(facility.breastEndocrineSurgery) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.breastEndocrineSurgerySpecialist === null
                          ? '0'
                          : (facility.breastEndocrineSurgerySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Cardiothoracic Surgery</td>
                    <td>{{ serviceType(facility.cardiothoracicSurgery) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.cardiothoracicSurgerySpecialist === null
                          ? '0'
                          : (facility.cardiothoracicSurgerySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Colorectal Surgery</td>
                    <td>{{ serviceType(facility.colorectalSurgery) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.colorectalSurgerySpecialist === null
                          ? '0'
                          : (facility.colorectalSurgerySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Hepatobiliary Surgery</td>
                    <td>{{ serviceType(facility.hepatobiliarySurgery) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.hepatobiliarySurgerySpecialist === null
                          ? '0'
                          : (facility.hepatobiliarySurgerySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Neurosurgery</td>
                    <td>{{ serviceType(facility.neurosurgery) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.neurosurgerySpecialist === null
                          ? '0'
                          : (facility.neurosurgerySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Plastic Surgery</td>
                    <td>{{ serviceType(facility.plasticSurgery) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.plasticSurgerySpecialist === null
                          ? '0'
                          : (facility.plasticSurgerySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Trauma Surgery</td>
                    <td>{{ serviceType(facility.traumaSurgery) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.traumaSurgerySpecialist === null
                          ? '0'
                          : (facility.traumaSurgerySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Upper GI Surgery</td>
                    <td>{{ serviceType(facility.upperGiSurgery) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.upperGiSurgerySpecialist === null
                          ? '0'
                          : (facility.upperGiSurgerySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Urology</td>
                    <td>{{ serviceType(facility.urology) }}</td>
                    <td style="text-align: right">
                      {{ facility.urologySpecialist === null ? '0' : (facility.urologySpecialist | number: '1.') }}
                    </td>
                  </tr>
                  <tr>
                    <td>Vascular Surgery</td>
                    <td>{{ serviceType(facility.vascularSurgery) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.vascularSurgerySpecialist === null
                          ? '0'
                          : (facility.vascularSurgerySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>ORL</td>
                    <td>{{ serviceType(facility.orl) }}</td>
                    <td style="text-align: right">
                      {{ facility.orlSpecialist === null ? '0' : (facility.orlSpecialist | number: '1.') }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Paediatric </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: auto">
              <table>
                <thead>
                  <tr>
                    <th style="width: 50%">Specialty</th>
                    <th style="width: 30%">Type</th>
                    <th style="width: 20%; text-align: right">Total Number of In House Specialist</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>General Paediatric</td>
                    <td>{{ serviceType(facility.paediatric) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.paediatricSpecialist === null ? '0' : (facility.paediatricSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Neonatology</td>
                    <td>{{ serviceType(facility.neonatology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.neonatologySpecialist === null ? '0' : (facility.neonatologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Paediatric Cardiology</td>
                    <td>{{ serviceType(facility.paediatricCardiology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.paediatricCardiologySpecialist === null
                          ? '0'
                          : (facility.paediatricCardiologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Paediatric Endocrinology</td>
                    <td>{{ serviceType(facility.paediatricEndocrinology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.paediatricEndocrinologySpecialist === null
                          ? '0'
                          : (facility.paediatricEndocrinologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Paediatric Haemato-oncology</td>
                    <td>{{ serviceType(facility.paediatricHaematoOncology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.paediatricHaematoOncologySpecialist === null
                          ? '0'
                          : (facility.paediatricHaematoOncologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Paediatric Intensive Care</td>
                    <td>{{ serviceType(facility.paediatricIntensiveCare) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.paediatricIntensiveCareSpecialist === null
                          ? '0'
                          : (facility.paediatricIntensiveCareSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Paediatric Nephrology</td>
                    <td>{{ serviceType(facility.paediatricNephrology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.paediatricNephrologySpecialist === null
                          ? '0'
                          : (facility.paediatricNephrologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Paediatric Neurology</td>
                    <td>{{ serviceType(facility.paediatricNeurology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.paediatricNeurologySpecialist === null
                          ? '0'
                          : (facility.paediatricNeurologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Paediatric Surgery</td>
                    <td>{{ serviceType(facility.paediatricSurgery) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.paediatricSurgerySpecialist === null
                          ? '0'
                          : (facility.paediatricSurgerySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> O&G </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: auto">
              <table>
                <thead>
                  <tr>
                    <th style="width: 50%">Specialty</th>
                    <th style="width: 30%">Type</th>
                    <th style="width: 20%; text-align: right">Total Number of In House Specialist</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>O&G</td>
                    <td>{{ serviceType(facility.ong) }}</td>
                    <td style="text-align: right">
                      {{ facility.ongSpecialist === null ? '0' : (facility.ongSpecialist | number: '1.') }}
                    </td>
                  </tr>
                  <tr>
                    <td>Gynaeoncology</td>
                    <td>{{ serviceType(facility.gynaeoncology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.gynaeoncologySpecialist === null
                          ? '0'
                          : (facility.gynaeoncologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Maternal Fetal Medicine</td>
                    <td>{{ serviceType(facility.maternalFoetal) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.maternalFoetalSpecialist === null
                          ? '0'
                          : (facility.maternalFoetalSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Reproductive Medicine</td>
                    <td>{{ serviceType(facility.reproductiveMedicine) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.reproductiveMedicineSpecialist === null
                          ? '0'
                          : (facility.reproductiveMedicineSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Urogynaecology</td>
                    <td>{{ serviceType(facility.urogynaecology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.urogynaecologySpecialist === null
                          ? '0'
                          : (facility.urogynaecologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Orthopaedic </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: auto">
              <table>
                <thead>
                  <tr>
                    <th style="width: 50%">Specialty</th>
                    <th style="width: 30%">Type</th>
                    <th style="width: 20%; text-align: right">Total Number of In House Specialist</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Orthopaedic Surgery</td>
                    <td>{{ serviceType(facility.orthopaedic) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.orthopaedicSpecialist === null ? '0' : (facility.orthopaedicSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Joint Arthroplasty</td>
                    <td>{{ serviceType(facility.jointArthroplasty) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.jointArthroplastySpecialist === null
                          ? '0'
                          : (facility.jointArthroplastySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Spine Orthopaedic/Surgery</td>
                    <td>{{ serviceType(facility.spineOrthopaedicSurgery) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.spineOrthopaedicSurgerySpecialist === null
                          ? '0'
                          : (facility.spineOrthopaedicSurgerySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Anaesthesiology </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: auto">
              <table>
                <thead>
                  <tr>
                    <th style="width: 50%">Specialty</th>
                    <th style="width: 30%">Type</th>
                    <th style="width: 20%; text-align: right">Total Number of In House Specialist</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Anaesthesiology</td>
                    <td>{{ serviceType(facility.anaesthesiology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.anaesthesiologySpecialist === null
                          ? '0'
                          : (facility.anaesthesiologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Adult Intensive Care</td>
                    <td>{{ serviceType(facility.adultIntensiveCare) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.adultIntensiveCareSpecialist === null
                          ? '0'
                          : (facility.adultIntensiveCareSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Cardiothoracic Anaesthesiology</td>
                    <td>{{ serviceType(facility.cardiothoracicAnaesthesiology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.cardiothoracicAnaesthesiologySpecialist === null
                          ? '0'
                          : (facility.cardiothoracicAnaesthesiologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Pain Medicine</td>
                    <td>{{ serviceType(facility.painMedicine) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.painMedicineSpecialist === null
                          ? '0'
                          : (facility.painMedicineSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Psychiatry </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: auto">
              <table>
                <thead>
                  <tr>
                    <th style="width: 50%">Specialty</th>
                    <th style="width: 30%">Type</th>
                    <th style="width: 20%; text-align: right">Total Number of In House Specialist</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Psychiatry</td>
                    <td>{{ serviceType(facility.psychiatry) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.psychiatrySpecialist === null ? '0' : (facility.psychiatrySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Child & Adolesent Psychiatry</td>
                    <td>{{ serviceType(facility.childAdolesentPsychiatry) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.childAdolesentPsychiatrySpecialist === null
                          ? '0'
                          : (facility.childAdolesentPsychiatrySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Radiology </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: auto">
              <table>
                <thead>
                  <tr>
                    <th style="width: 50%">Specialty</th>
                    <th style="width: 30%">Type</th>
                    <th style="width: 20%; text-align: right">Total Number of In House Specialist</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Radiology</td>
                    <td>{{ serviceType(facility.radiology) }}</td>
                    <td style="text-align: right">
                      {{ facility.radiologySpecialist === null ? '0' : (facility.radiologySpecialist | number: '1.') }}
                    </td>
                  </tr>
                  <tr>
                    <td>Interventional Radiology</td>
                    <td>{{ serviceType(facility.interventionalRadiology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.interventionalRadiologySpecialist === null
                          ? '0'
                          : (facility.interventionalRadiologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Pathology </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: auto">
              <table>
                <thead>
                  <tr>
                    <th style="width: 50%">Specialty</th>
                    <th style="width: 30%">Type</th>
                    <th style="width: 20%; text-align: right">Total Number of In House Specialist</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Pathology</td>
                    <td>{{ serviceType(facility.pathology) }}</td>
                    <td style="text-align: right">
                      {{ facility.pathologySpecialist === null ? '0' : (facility.pathologySpecialist | number: '1.') }}
                    </td>
                  </tr>
                  <tr>
                    <td>Anatomical Pathology</td>
                    <td>{{ serviceType(facility.anatomicalPathology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.anatomicalPathologySpecialist === null
                          ? '0'
                          : (facility.anatomicalPathologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Chemical Pathology</td>
                    <td>{{ serviceType(facility.chemicalPathology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.chemicalPathologySpecialist === null
                          ? '0'
                          : (facility.chemicalPathologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Hematopathology</td>
                    <td>{{ serviceType(facility.hematopathology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.hematopathologySpecialist === null
                          ? '0'
                          : (facility.hematopathologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Microbiology</td>
                    <td>{{ serviceType(facility.microbiology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.microbiologySpecialist === null
                          ? '0'
                          : (facility.microbiologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Transfusion Medicine</td>
                    <td>{{ serviceType(facility.transfusionMedicine) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.transfusionMedicineSpecialist === null
                          ? '0'
                          : (facility.transfusionMedicineSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Forensic Medicine</td>
                    <td>{{ serviceType(facility.forensicMedicine) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.forensicMedicineSpecialist === null
                          ? '0'
                          : (facility.forensicMedicineSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Ophthalmology </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: auto">
              <table>
                <thead>
                  <tr>
                    <th style="width: 50%">Specialty</th>
                    <th style="width: 30%">Type</th>
                    <th style="width: 20%; text-align: right">Total Number of In House Specialist</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Ophthalmology</td>
                    <td>{{ serviceType(facility.ophthalmology) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.ophthalmologySpecialist === null
                          ? '0'
                          : (facility.ophthalmologySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Vitreo-retinal</td>
                    <td>{{ serviceType(facility.vitreoRetinal) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.vitreoRetinalSpecialist === null
                          ? '0'
                          : (facility.vitreoRetinalSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Glaucoma</td>
                    <td>{{ serviceType(facility.glaucoma) }}</td>
                    <td style="text-align: right">
                      {{ facility.glaucomaSpecialist === null ? '0' : (facility.glaucomaSpecialist | number: '1.') }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Dental </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow-x: auto">
              <table>
                <thead>
                  <tr>
                    <th style="width: 50%">Specialty</th>
                    <th style="width: 30%">Type</th>
                    <th style="width: 20%; text-align: right">Total Number of In House Specialist</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Dental Special Care</td>
                    <td>{{ serviceType(facility.dentalSpecialCare) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.dentalSpecialCareSpecialist === null
                          ? '0'
                          : (facility.dentalSpecialCareSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Forensic Dental</td>
                    <td>{{ serviceType(facility.forensicDental) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.forensicDentalSpecialist === null
                          ? '0'
                          : (facility.forensicDentalSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Oral Pathology/Medicine</td>
                    <td>{{ serviceType(facility.oralPathologyMedicine) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.oralPathologyMedicineSpecialist === null
                          ? '0'
                          : (facility.oralPathologyMedicineSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Oral Surgery</td>
                    <td>{{ serviceType(facility.oralSurgery) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.oralSurgerySpecialist === null ? '0' : (facility.oralSurgerySpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Paediatric Dental</td>
                    <td>{{ serviceType(facility.paediatricDental) }}</td>
                    <td style="text-align: right">
                      {{
                        facility.paediatricDentalSpecialist === null
                          ? '0'
                          : (facility.paediatricDentalSpecialist | number: '1.')
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-tab>

      <mat-tab label="Special Initiatives">
        <div fxFlex fxLayoutAlign="start center"><h3>Hospital Information System</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Manual: &nbsp;<strong>{{ manual === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              HIS@KKM: &nbsp;<strong>{{ hiskkm === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              SPPD: &nbsp;<strong>{{ sppd === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              SPP: &nbsp;<strong>{{ spp === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Proprietary: &nbsp;<strong>{{ proprietary === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              BBIS: &nbsp;<strong>{{ bbis === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <div fxFlex fxLayoutAlign="start center"><h3>Accreditation</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              MSQH: &nbsp;<strong>{{ facility.accrediation === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Year of Accreditation: &nbsp;<strong>{{ facility.accrediationYear }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              JCI: &nbsp;<strong>{{ facility.accrediationJci === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Year of Accreditation: &nbsp;<strong>{{ facility.accrediationYearJci }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Others: &nbsp;<strong>{{ facility.accrediationOther === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Year of Accreditation: &nbsp;<strong>{{ facility.accrediationYearOther }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Accreditation Body: &nbsp;<strong>{{ facility.accrediationBodyOther }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
        <div fxFlex fxLayoutAlign="start center"><h3>Special Activities</h3></div>
        <mat-grid-list [cols]="breakpoint" rowHeight="2rem" (window:resize)="onResize($event)">
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              CaseMix: &nbsp;<strong>{{ facility.casemix === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Full Paying Patients: &nbsp;<strong>{{ facility.fpp === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Hospital Mesra Ibadah: &nbsp;<strong>{{ facility.awarenessProgram === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div fxFlex fxLayoutAlign="start center">
              Lean Healthcare: &nbsp;<strong>{{ facility.leanHealthcare === 'N' ? 'No' : 'Yes' }}</strong>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-tab>

      <mat-tab label="RMK Project Development">
        <div style="overflow-x: auto">
          <table>
            <thead>
              <tr>
                <th>Project Name</th>
                <th>RMK</th>
                <th>Rolling Plan</th>
                <th>Project Phase</th>
                <th>Expected Completion Date</th>
                <th>Completion %</th>
                <th>Project Submission Date</th>
              </tr>
            </thead>
            <!-- <tbody>
              <tr *ngFor="let project of projects">
                <td>{{ project.projectName }}</td>
                <td>{{ project.malaysiaPlan }}</td>
                <td>{{ project.rollingPlanHasOne.fullDesc }}</td>
                <td>{{ project.projectPhaseHasOne.fullDesc }}</td>
                <td>{{ project.expectedCompletionDate }}</td>
                <td style="text-align: right">{{ project.completionPercent }}</td>
                <td>{{ project.projectSubmitDate }}</td>
              </tr>
            </tbody> -->
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>

    <mat-action-row>
      <button mat-button aria-label="Close" (click)="toggleDetail = false; toggleResult = true">
        <span translate>Close</span>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</div>
