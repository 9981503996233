import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Hospital } from './hospital';
import { Facility } from './facility';
import { Observable } from 'rxjs';
import { State } from './state';
import { Reference } from './reference';
import { Project } from './project';

@Injectable({
  providedIn: 'root',
})
export class HospitalService {
  private HospitalsUrl: string;

  constructor(private http: HttpClient) {
  //   this.HospitalsUrl = 'http://localhost:8080/api/v1';
    this.HospitalsUrl = 'https://www.hospital.moh.gov.my/api/v1';
  }

  public search(): Observable<Hospital[]> {
    return this.http.get<Hospital[]>(this.HospitalsUrl + '/search');
  }

  public search2(): Observable<Hospital[]> {
    return this.http.get<Hospital[]>(this.HospitalsUrl + '/searchByFacilityCategory');
  }

  public advanceSearch(filter: any): Observable<Hospital[]> {
    return this.http.post<Hospital[]>(this.HospitalsUrl + '/advance-search', filter);
  }

  public findById(id: string): Observable<Facility> {
    return this.http.get<Facility>(this.HospitalsUrl + '/facility/' + id);
  }

  public findProjects(id: string): Observable<Project[]> {
    return this.http.get<Project[]>(this.HospitalsUrl + '/facility/projects/' + id);
  }

  public getStates(): Observable<State[]> {
    return this.http.get<State[]>(this.HospitalsUrl + '/states');
  }

  public getCategories(): Observable<Reference[]> {
    return this.http.get<Reference[]>(this.HospitalsUrl + '/reference-code/categories');
  }

  public getClinicalServices(): Observable<Reference[]> {
    return this.http.get<Reference[]>(this.HospitalsUrl + '/reference-code/clinical-services');
  }

  public getEquipments(): Observable<Reference[]> {
    return this.http.get<Reference[]>(this.HospitalsUrl + '/reference-code/equipments');
  }

  public getBeds(): Observable<Reference[]> {
    return this.http.get<Reference[]>(this.HospitalsUrl + '/reference-code/beds');
  }

  public getLeadHospital(id: string): Observable<Project[]> {
    return this.http.get<Project[]>(this.HospitalsUrl + '/search-map/' + id);
  }
}
